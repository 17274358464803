<template>
  <ui-portal>
    <ui-modal
      class="account-edit-password-modal"
      :title="$t('CHANGE_PASSWORD_TITLE')"
      :show-close-btn="!isSubmitting"
      @close="close"
    >
      <form
        class="account-edit-password-modal__form"
        :class="{ 'account-edit-password-modal__form_loading': isSubmitting }"
        @submit.prevent="submit"
      >
        <div class="account-edit-password-modal__field">
          {{ $t('NEW_PASSWORD_LBL') }}
          <ui-text
            v-model="form.password"
            class="account-edit-password-modal__field-input"
            type="password"
            :placeholder="$t('NEW_PASSWORD_PH')"
            :is-error="Boolean(formErrors.password)"
          >
            <template #error>
              {{ $t(formErrors.password, {
                minLength: MIN_PASSWORD_LENGTH,
                maxLength: MAX_PASSWORD_LENGTH,
              }) }}
            </template>
          </ui-text>
        </div>

        <div class="account-edit-password-modal__field">
          {{ $t('NEW_PASSWORD_CONFIRM_LBL') }}
          <ui-text
            v-model="form.passwordConfirm"
            class="account-edit-password-modal__field-input"
            type="password"
            :placeholder="$t('NEW_PASSWORD_CONFIRM_PH')"
            :is-error="Boolean(formErrors.passwordConfirm)"
          >
            <template #error>
              {{ $t(formErrors.passwordConfirm) }}
            </template>
          </ui-text>
        </div>

        <div class="account-edit-password-modal__buttons">
          <ui-button
            type="submit"
            class="account-edit-password-modal__btn"
          >
            {{ $t('CHANGE_PWD_BTN') }}
          </ui-button>

          <ui-button
            @click="close"
            type="button"
            class="account-edit-password-modal__btn"
            look="secondary"
            fill="frame-hover"
          >
            {{ $t('CANCEL_BTN') }}
          </ui-button>
        </div>
      </form>

      <ui-spinner
        class="account-edit-password-modal__loader"
        overlay
        type="pills"
        :show="isSubmitting"
      />
    </ui-modal>
  </ui-portal>
</template>

<script>
import {
  UiButton,
  UiText,
  UiSpinner,
  UiModal,
  UiPortal,
} from '@shelf.network/ui-kit'

import { mapActions } from 'vuex'
import { extensionAccountsActions } from '../../store/types'

import { passwordFormSchema } from '../../form-schemas/password'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'Utils/formValidators'

import { ExtensionAccount } from 'Models/ExtensionAccount'

import { scrollToErrorInModal } from 'Utils/scrollTo'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'account-edit-password-modal',
  components: {
    UiButton,
    UiText,
    UiSpinner,
    UiModal,
    UiPortal,
  },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  data () {
    return {
      form: passwordFormSchema.form,
      formErrors: passwordFormSchema.formErrors,
      isSubmitting: false,
    }
  },

  computed: {
    MIN_PASSWORD_LENGTH: () => MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH: () => MAX_PASSWORD_LENGTH,
  },

  created () {
    passwordFormSchema.resetFields()
  },

  methods: {
    ...mapActions('ui/extension-accounts', {
      updatePassword: extensionAccountsActions.UPDATE_PASSWORD,
    }),

    async submit () {
      if (!passwordFormSchema.validateForm()) {
        scrollToErrorInModal()
        return
      }

      this.isSubmitting = true
      try {
        await this.updatePassword({
          id: this.account.id,
          value: this.form.password
        })

        showSuccess(this.$t('PASSWORD_CHANGED_MSG', {
          account: this.account.id
        }))
        this.close()
      } catch (err) {
        console.error(err)
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
      }
      this.isSubmitting = false
    },

    close () {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.account-edit-password-modal {
  &__form {
    max-width: 100%;
    width: 30em;

    &_loading {
      filter: blur(0.2em);
    }
  }

  &__field {
    margin-bottom: 2em;

    &-input {
      margin-top: 0.4em;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid $color-light-grey;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    min-width: 14em;

    @include respond-below(sm) {
      min-width: 0;
    }
  }

  & /deep/ .modal__content {
    max-width: 36em;

    @include respond-below(sm) {
      max-width: unset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CHANGE_PWD_BTN": "Change password",
    "CHANGE_PASSWORD_TITLE": "Password change",
    "CANCEL_BTN": "Cancel",
    "NEW_PASSWORD_LBL": "NEW PASSWORD",
    "NEW_PASSWORD_PH": "Pick a password",
    "NEW_PASSWORD_CONFIRM_LBL": "PASSWORD AGAIN",
    "NEW_PASSWORD_CONFIRM_PH": "Repeat the password",
    "PASSWORD_CHANGED_MSG": "Password changed for account {account}"
  },
  "ka": {
    "CHANGE_PWD_BTN": "პაროლის ცვლილება",
    "CHANGE_PASSWORD_TITLE": "პაროლის ცვლილება",
    "CANCEL_BTN": "დახურვა",
    "NEW_PASSWORD_LBL": "ᲐᲮᲐᲚᲘ ᲞᲐᲠᲝᲚᲘ",
    "NEW_PASSWORD_PH": "ჩაწერე პაროლი",
    "NEW_PASSWORD_CONFIRM_LBL": "ᲒᲐᲘᲛᲔᲝᲠᲔ ᲞᲐᲠᲝᲚᲘ",
    "NEW_PASSWORD_CONFIRM_PH": "გაიმეორე პაროლი",
    "PASSWORD_CHANGED_MSG": "{account} პაროლი შეიცვალა"
  },
  "ru": {
    "CHANGE_PWD_BTN": "Сменить пароль",
    "CHANGE_PASSWORD_TITLE": "Смена пароля",
    "CANCEL_BTN": "Закрыть",
    "NEW_PASSWORD_LBL": "НОВЫЙ ПАРОЛЬ",
    "NEW_PASSWORD_PH": "Придумайте пароль",
    "NEW_PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "NEW_PASSWORD_CONFIRM_PH": "Повторите пароль",
    "PASSWORD_CHANGED_MSG": "Пароль для аккаунта {account} изменён"
  },
  "uk": {
    "CHANGE_PWD_BTN": "Змінити пароль",
    "CHANGE_PASSWORD_TITLE": "Зміна пароля",
    "CANCEL_BTN": "Закрити",
    "NEW_PASSWORD_LBL": "НОВИЙ ПАРОЛЬ",
    "NEW_PASSWORD_PH": "Придумайте пароль",
    "NEW_PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "NEW_PASSWORD_CONFIRM_PH": "Повторіть пароль",
    "PASSWORD_CHANGED_MSG": "Пароль для акаунта {account} змінено"
  }
}
</i18n>
