<template>
  <div class="accounts-list-head accounts-cmn__row">
    <span class="accounts-list-head__cell">
      {{ $t('COPART_ACCOUNT_HEAD_CELL') }}
    </span>

    <span class="accounts-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>

    <span class="accounts-list-head__cell">
      {{ $t('DEALERS_HEAD_CELL') }}
    </span>

    <span class="accounts-list-head__cell">
      {{ $t('VEHICLES_HEAD_CELL') }}
    </span>

    <span class="accounts-list-head__cell">
      {{ $t('LIMIT_HEAD_CELL') }}
    </span>

    <span class="accounts-list-head__cell">
      {{ $t('FEE_HEAD_CELL') }}
    </span>

    <span class="accounts-list-head__cell">
      {{ $t('STATUS_HEAD_CELL') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'accounts-list-head',
}
</script>

<style lang="scss" scoped>
@import "../styles/accounts.scss";

.accounts-list-head {
  padding: 2em 3em 0;

  &__cell {
    @extend .accounts-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "COPART_ACCOUNT_HEAD_CELL": "COPART ACCOUNT",
    "LOCATION_HEAD_CELL": "LOCATION",
    "DEALERS_HEAD_CELL": "DEALERS",
    "VEHICLES_HEAD_CELL": "VEHICLES",
    "LIMIT_HEAD_CELL": "LIMIT",
    "FEE_HEAD_CELL": "FEE STRUCTURE",
    "STATUS_HEAD_CELL": "STATUS"
  },
  "ka": {
    "COPART_ACCOUNT_HEAD_CELL": "COPART-ᲖᲔ ᲨᲔᲡᲕᲚᲐ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "DEALERS_HEAD_CELL": "ᲓᲘᲚᲔᲠᲘ",
    "VEHICLES_HEAD_CELL": "ᲛᲐᲜᲥᲐᲜᲔᲑᲘ",
    "LIMIT_HEAD_CELL": "ᲚᲘᲛᲘᲢᲘ",
    "FEE_HEAD_CELL": "ᲓᲐᲠᲘᲪᲮᲕᲐ",
    "STATUS_HEAD_CELL": "ᲡᲢᲐᲢᲣᲡᲘ"
  },
  "ru": {
    "COPART_ACCOUNT_HEAD_CELL": "АККАУНТ COPART",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ",
    "DEALERS_HEAD_CELL": "ДИЛЕРЫ",
    "VEHICLES_HEAD_CELL": "АВТО",
    "LIMIT_HEAD_CELL": "ЛИМИТ",
    "FEE_HEAD_CELL": "КОМИССИЯ",
    "STATUS_HEAD_CELL": "СТАТУС"
  },
  "uk": {
    "COPART_ACCOUNT_HEAD_CELL": "АКАУНТ COPART",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ",
    "DEALERS_HEAD_CELL": "ДИЛЕРИ",
    "VEHICLES_HEAD_CELL": "АВТО",
    "LIMIT_HEAD_CELL": "ЛІМІТ",
    "FEE_HEAD_CELL": "КОМІСІЯ",
    "STATUS_HEAD_CELL": "СТАТУС"
  }
}
</i18n>
