<template>
  <div class="account-cell-fee-level">
    <p class="accounts-cmn__str accounts-cmn__str_sec">
      {{ feeLevelsMap[account.feeLevel] || account.feeLevel }}
    </p>

    <div
      v-if="account.feeLevel === FEE_LEVELS.LOW"
      class="account-cell-fee-level__tooltip"
      :aria-label="$t('LOW_FEE_LEVEL_TOOLTIP')"
      role="tooltip"
      data-microtip-position="top"
      data-microtip-size="medium"
    >
      <ui-icon
        class="account-cell-fee-level__tooltip-icon"
        icon="info-alt"
      />
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { ExtensionAccount } from 'Models/ExtensionAccount'
import { FEE_LEVELS } from '../constants'

export default {
  name: 'account-cell-fee-level',
  components: { UiIcon },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  computed: {
    feeLevelsMap () {
      return {
        [FEE_LEVELS.LOW]: this.$t('FEE_LEVEL_LOW'),
        [FEE_LEVELS.HIGH]: this.$t('FEE_LEVEL_HIGH'),
      }
    },

    FEE_LEVELS: () => FEE_LEVELS,
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/accounts";

.account-cell-fee-level {
  &__tooltip {
    color: $color-dark-grey;
    cursor: help;

    &::after {
      line-height: 1.5;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "FEE_LEVEL_LOW": "LOW",
    "FEE_LEVEL_HIGH": "HIGH",
    "LOW_FEE_LEVEL_TOOLTIP": "If invoice fee is greater than calculated fee, account will be disabled"
  },
  "ka": {
    "FEE_LEVEL_LOW": "ᲓᲐᲑᲐᲚᲘ",
    "FEE_LEVEL_HIGH": "ᲛᲐᲦᲐᲚᲘ",
    "LOW_FEE_LEVEL_TOOLTIP": "თუ ინვოისის ღირებულება, დარიცხვაზე მეტია ექაუნთი გაუქმნდება"
  },
  "ru": {
    "FEE_LEVEL_LOW": "НИЗКАЯ",
    "FEE_LEVEL_HIGH": "ВЫСОКАЯ",
    "LOW_FEE_LEVEL_TOOLTIP": "Если комиссия инвойса превысит расчётную, аккаунт будет отключен"
  },
  "uk": {
    "FEE_LEVEL_LOW": "НИЗЬКА",
    "FEE_LEVEL_HIGH": "ВИСОКА",
    "LOW_FEE_LEVEL_TOOLTIP": "Якщо комісія інвойсу перевищить розрахункову, аккаунт буде відключений"
  }
}
</i18n>
