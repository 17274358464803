<template>
  <div class="account-cell-bid-limit accounts-cmn__cell">
    <btn-dropdown
      class="account-cell-bid-limit__btn"
      ref="bidLimitDropdown"
      @open="clearForm"
    >
      <template slot="btn-txt">
        <template v-if="Number(account.bidLimit) > 0">
          {{ $fc(account.bidLimit, BID_LIMIT_CURRENCY) }}
        </template>

        <template v-else>
          {{ $t('NOT_SET_MSG') }}
        </template>
      </template>

      <template slot="content-title">
        {{ $t('DROPDOWN_TITLE') }}
      </template>

      <template slot="content-main">
        <number-input
          class="account-cell-bid-limit__dropdown-number-input"
          v-model="form.bidLimit"
          :is-disabled="isApplying"
          :step="100"
          :min="0"
        />
      </template>

      <template slot="content-action">
        <ui-button
          class="account-cell-bid-limit__dropdown-action"
          :is-disabled="isApplying"
          @click="apply(form.bidLimit)"
        >
          {{ $t('APPLY_BTN') }}
        </ui-button>

        <ui-button
          v-if="Number(account.bidLimit) > 0"
          class="account-cell-bid-limit__dropdown-action"
          fill="none"
          look="danger"
          :is-disabled="isApplying"
          @click="apply(0)"
        >
          {{ $t('REMOVE_BTN') }}
        </ui-button>
      </template>
    </btn-dropdown>
  </div>
</template>

<script>
import { UiButton } from '@shelf.network/ui-kit'
import BtnDropdown from 'Common/BtnDropdown'
import NumberInput from 'Common/NumberInput'

import { mapActions } from 'vuex'
import { extensionAccountsActions } from '../store/types'
import { BID_LIMIT_CURRENCY } from '../constants'

import { showSuccess, showError } from 'Utils/notifications'
import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  name: 'account-cell-bid-limit',
  components: {
    BtnDropdown,
    NumberInput,
    UiButton,
  },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  data () {
    return {
      form: {
        bidLimit: 0,
      },
      isApplying: false,
    }
  },

  computed: {
    BID_LIMIT_CURRENCY: () => BID_LIMIT_CURRENCY
  },

  created () {
    this.clearForm()
  },

  methods: {
    ...mapActions('ui/extension-accounts', {
      updateBidLimit: extensionAccountsActions.UPDATE_BID_LIMIT,
    }),

    clearForm () {
      this.form.bidLimit = Number(this.account.bidLimit)
    },

    async apply (value) {
      this.isApplying = true
      try {
        await this.updateBidLimit({
          id: this.account.id,
          value,
        })
        showSuccess(this.$t('APPLY_SUCCESS_NOTIFY'))
      } catch (error) {
        this.clearForm()
        showError(this.$t('APPLY_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isApplying = false

      this.close()
    },

    close () {
      this.$refs.bidLimitDropdown.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/accounts.scss";

.account-cell-bid-limit {
  &__btn {
    /deep/ .btn-dropdown {
      &__toggle-txt,
      &__toggle-arrow {
        color: $color-ui-secondary;
      }

      &__content {
        &-actions {
          grid-auto-flow: row;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DROPDOWN_TITLE": "Edit bid limit",
    "APPLY_BTN": "Apply",
    "APPLY_SUCCESS_NOTIFY": "Bid limit applied",
    "APPLY_FAILED_NOTIFY": "Bid limit could not be applied. Please try again or contact the system owner",
    "NOT_SET_MSG": "Not set",
    "REMOVE_BTN": "Remove"
  },
  "ka": {
    "DROPDOWN_TITLE": "ლიმიტის ცვლილება",
    "APPLY_BTN": "ცვლილება",
    "APPLY_SUCCESS_NOTIFY": "ლიმიტი განახლდა",
    "APPLY_FAILED_NOTIFY": "ბიჯის ლიმიტი ვერ განახლდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს",
    "NOT_SET_MSG": "არ არის მითითებული",
    "REMOVE_BTN": "წაშლა"
  },
  "ru": {
    "DROPDOWN_TITLE": "Новый лимит ставки",
    "APPLY_BTN": "Применить",
    "APPLY_SUCCESS_NOTIFY": "Лимит ставки установлен",
    "APPLY_FAILED_NOTIFY": "Не удалось установить лимит ставки. Повторите попытку позже или свяжитесь с владельцем системы",
    "NOT_SET_MSG": "Не установлен",
    "REMOVE_BTN": "Удалить"
  },
  "uk": {
    "DROPDOWN_TITLE": "Новий ліміт ставки",
    "APPLY_BTN": "Застосувати",
    "APPLY_SUCCESS_NOTIFY": "Ліміт ставки установлено",
    "APPLY_FAILED_NOTIFY": "Не вдалося установити ліміт ставки. Повторіть спробу пізніше або зв’яжіться з власником системи",
    "NOT_SET_MSG": "Не встановлено",
    "REMOVE_BTN": "Скинути"
  }
}
</i18n>
