import { createFormSchema } from 'Utils/createFormSchema'
import {
  required,
  password,
  confirmPassword,
  maxLength,
  min,
} from 'Utils/formValidators'
import {
  FEE_LEVELS,
  MAX_COPART_LOGIN_LENGTH,
  MIN_BID_LIMIT
} from '../constants'

export const createAccountFormSchema = createFormSchema({
  form: {
    copartLogin: '',
    country: '',
    bidLimit: 0,
    feeLevel: FEE_LEVELS.HIGH,
    password: '',
    passwordConfirm: '',
  },

  validators: {
    copartLogin: [required, maxLength(MAX_COPART_LOGIN_LENGTH)],
    country: [required],
    bidLimit: [required, min(MIN_BID_LIMIT)],
    feeLevel: [required],
    password: [required, password],
    passwordConfirm: [
      required,
      confirmPassword(form => form.password)
    ]
  }
})
