import { Command } from '@/commands/Command'

/**
 * @typedef {object} DealerCommandArgsDef
 * @property {Identity|UserEntry} identity Identity to operate over
 *
 * @typedef {import('@/commands/Command').CommandArgs} CommandArgs
 * @typedef {CommandArgs & DealerCommandArgsDef} DealerCommandArgs
 */

export class DealerCommand extends Command {
  /**
   * Create a configured DealerCommand command
   * @param {DealerCommandArgs} opts
   */
  constructor (opts) {
    super(opts)
    this._dealerEntry = opts.dealerEntry
  }
}
