<template>
  <list-params
    class="accounts-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <div class="accounts-list-params__row">
        <label class="list-params-row__label">
          {{ $t('BID_LIMIT_LBL') }}
        </label>

        <div class="accounts-list-params__fields">
          <ui-text
            v-model="form.minBidLimit"
            class="accounts-list-params__field"
            type="number"
            :min="MIN_BID_LIMIT"
            :placeholder="$t('MIN_BID_LIMIT_PH')"
          >
            <template #dock-left>
              {{ bidLimitCurrencySymbol }}
            </template>
          </ui-text>

          <ui-text
            v-model="form.maxBidLimit"
            class="accounts-list-params__field"
            type="number"
            :min="MIN_BID_LIMIT"
            :placeholder="$t('MAX_BID_LIMIT_PH')"
          >
            <template #dock-left>
              {{ bidLimitCurrencySymbol }}
            </template>
          </ui-text>
        </div>
      </div>

      <list-params-flags
        class="accounts-list-params__row"
        label=" "
        :value="{
          isInvalidCredentials: {
            label: $t('FLAG_INVALID_CREDENTIALS_LBL'),
            value: form.isInvalidCredentials,
          }
        }"
        @input="form[$event.key] = $event.value"
      />
    </template>
  </list-params>
</template>

<script>
import { UiText } from '@shelf.network/ui-kit'
import {
  ListParams,
  ListParamsFlags,
} from 'Common/ListParams'

import {
  BID_LIMIT_CURRENCY,
  MIN_BID_LIMIT
} from '../constants'
import { currencySymbols } from 'Utils/currencySymbols'

export default {
  name: 'accounts-list-params',
  components: {
    UiText,
    ListParams,
    ListParamsFlags,
  },

  props: {
    value: { type: Object, required: true },
  },

  data: _ => ({
    form: {
      minBidLimit: '',
      maxBidLimit: '',
      isInvalidCredentials: false
    },
  }),

  computed: {
    bidLimitCurrencySymbol () {
      return currencySymbols[BID_LIMIT_CURRENCY]
    },

    MIN_BID_LIMIT: () => MIN_BID_LIMIT,
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/ListParams/styles/list-params-row.scss";

.accounts-list-params {
  &__fields {
    display: grid;
    grid: auto / repeat(auto-fit, minmax(16.5em, 1fr));
    gap: 1em;
  }

  &__field {
    /deep/ .ui-text {
      &__dock {
        width: 3em;
      }

      &__dock_left ~ .ui-text__input {
        padding-left: 2.6em;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "BID_LIMIT_LBL": "BY BID LIMIT",
    "MIN_BID_LIMIT_PH": "Minimum limit",
    "MAX_BID_LIMIT_PH": "Maximum limit",
    "FLAG_INVALID_CREDENTIALS_LBL": "Invalid credentials"
  },
  "ka": {
    "BID_LIMIT_LBL": "ᲚᲘᲛᲘᲢᲘᲡ ᲛᲘᲮᲔᲓᲕᲘᲗ",
    "MIN_BID_LIMIT_PH": "მინ. ლიმიტი",
    "MAX_BID_LIMIT_PH": "მაქს. ლიმიტი",
    "FLAG_INVALID_CREDENTIALS_LBL": "არასწორი მონაცემები"
  },
  "ru": {
    "BID_LIMIT_LBL": "ПО ЛИМИТУ СТАВКИ",
    "MIN_BID_LIMIT_PH": "Минимальный лимит",
    "MAX_BID_LIMIT_PH": "Максимальный лимит",
    "FLAG_INVALID_CREDENTIALS_LBL": "Неверный пароль"
  },
  "uk": {
    "BID_LIMIT_LBL": "ЗА ЛІМІТОМ СТАВКИ",
    "MIN_BID_LIMIT_PH": "Мінімальний ліміт",
    "MAX_BID_LIMIT_PH": "Максимальний ліміт",
    "FLAG_INVALID_CREDENTIALS_LBL": "Неправильний пароль"
  }
}
</i18n>
