<template>
  <button
    class="op-ios account-op account-op__btn"
    :disabled="isDisabled"
    @click="onClick"
  >
    <template v-if="account.isIosAllowed">
      <icon-combo
        class="op-ios__icon-combo account-op__btn-ico"
        icon="mobile"
        icon-addition="cross-bold"
      />

      <span class="account-op__btn-txt">
        {{ $t('DISABLE_IOS_BTN') }}
      </span>
    </template>

    <template v-else>
      <icon-combo
        class="op-ios__icon-combo account-op__btn-ico op-ios__icon-combo_enable"
        icon="mobile"
        icon-addition="plus-bold"
      />

      <span class="account-op__btn-txt">
        {{ $t('ENABLE_IOS_BTN') }}
      </span>
    </template>

    <ui-spinner
      class="op-ios__spinner account-op__overlay-spinner"
      v-if="isExecuting"
      type="pills"
      :overlay="true"
    />
  </button>
</template>

<script>
import { UiSpinner } from '@shelf.network/ui-kit'
import IconCombo from 'Common/IconCombo'

import { mapActions } from 'vuex'
import { extensionAccountsActions } from '../../store/types'

import { ExtensionAccount } from 'Models/ExtensionAccount'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'op-ios',
  components: {
    IconCombo,
    UiSpinner,
  },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  data () {
    return {
      isExecuting: false,
    }
  },

  computed: {
    isDisabled () {
      return this.isExecuting ||
        !this.account.isLoginAllowed ||
        !this.account.isConnectorAvailable
    }
  },

  methods: {
    ...mapActions('ui/extension-accounts', {
      updateIsIosAllowed: extensionAccountsActions.UPDATE_IS_IOS_ALLOWED,
    }),

    async onClick () {
      if (this.isExecuting) return

      this.isExecuting = true
      try {
        await this.updateIsIosAllowed({
          id: this.account.id,
          value: !this.account.isIosAllowed
        })

        const successMsg = this.account.isIosAllowed
          ? this.$t('IOS_ENABLED_MSG', { account: this.account.id })
          : this.$t('IOS_DISABLED_MSG', { account: this.account.id })
        showSuccess(successMsg)
      } catch (err) {
        console.error(err)

        const errorMsg = err.httpStatus === 400
          ? this.$t('IOS_UPDATE_ACCOUNT_ERROR', { account: this.account.id })
          : this.$t('TOASTS.UNEXPECTED_ERROR')
        showError(errorMsg)
      }
      this.isExecuting = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/accounts-operations.scss";

.op-ios {
  position: relative;

  &__icon-combo {
    --addition-color: #{$color-flag-is-error};

    &_enable {
      --addition-color: #{$color-flag-is-success};
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ENABLE_IOS_BTN": "Enable iOS app",
    "DISABLE_IOS_BTN": "Disable iOS app",
    "IOS_ENABLED_MSG": "iOS app enabled for account {account}",
    "IOS_DISABLED_MSG": "iOS app disabled for account {account}",
    "IOS_UPDATE_ACCOUNT_ERROR": "Dealers limit exceeded for account {account}"
  },
  "ka": {
    "ENABLE_IOS_BTN": "iOS აპლიკაციის ჩართვა",
    "DISABLE_IOS_BTN": "iOS აპლიკაციის გამორთვა",
    "IOS_ENABLED_MSG": "iOS ჩართულია ექაუნთზე {account}",
    "IOS_DISABLED_MSG": "iOS გამორთულია ექაუნთისთვის {account}",
    "IOS_UPDATE_ACCOUNT_ERROR": "დილერების ლიმიტი შევსებულია ექაუნთზე {account}"
  },
  "ru": {
    "ENABLE_IOS_BTN": "Включить iOS приложение",
    "DISABLE_IOS_BTN": "Выключить iOS приложение",
    "IOS_ENABLED_MSG": "iOS приложение включено для аккаунта {account}",
    "IOS_DISABLED_MSG": "iOS приложение выключено для аккаунта {account}",
    "IOS_UPDATE_ACCOUNT_ERROR": "Лимит дилеров превышен для аккаунта {account}"
  },
  "uk": {
    "ENABLE_IOS_BTN": "Увімкнути iOS додаток",
    "DISABLE_IOS_BTN": "Вимкнути iOS додаток",
    "IOS_ENABLED_MSG": "iOS додаток увімкнено для акаунта {account}",
    "IOS_DISABLED_MSG": "iOS додаток вимкнено для акаунта {account}",
    "IOS_UPDATE_ACCOUNT_ERROR": "Перевищено ліміт дилерів для акаунта {account}"
  }
}
</i18n>
