<template>
  <button
    class="op-delete account-op account-op__btn"
    @click="onClick"
  >
    <ui-icon
      class="account-op__btn-ico"
      icon="trash-alt"
    />

    <span class="account-op__btn-txt">
      {{ $t('DELETE_BTN') }}
    </span>
  </button>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { ExtensionAccount } from 'Models/ExtensionAccount'
import { confirmAction } from 'Utils/confirmAction'
import { DeleteAccount } from '../../commands/DeleteAccount'

export default {
  name: 'op-delete',
  components: {
    UiIcon,
  },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  methods: {
    async onClick () {
      const msg = this.$t('DELETE_CONFIRM_MSG', { name: this.account.id })
      if (!await confirmAction(msg)) {
        return
      }
      const cmd = new DeleteAccount({ dealerEntry: this.account })
      await cmd.execute()
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/accounts-operations.scss";
</style>

<i18n>
{
  "en": {
    "DELETE_BTN": "Delete",
    "DELETE_CONFIRM_MSG": "Are you sure you want to delete {name}? The action is irreversible."
  },
  "ka": {
    "DELETE_BTN": "წაშლა",
    "DELETE_CONFIRM_MSG": "გსურთ რომ წაშალოთ {name}?"
  },
  "ru": {
    "DELETE_BTN": "Удалить",
    "DELETE_CONFIRM_MSG": "Уверены, что желаете удалить {name}? Действие необратимо."
  },
  "uk": {
    "DELETE_BTN": "Вилучити",
    "DELETE_CONFIRM_MSG": "Впевнені, що бажаєте вилучити {name}? Дія незворотна."
  }
}
</i18n>
