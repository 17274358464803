<template>
  <div class="account-item">
    <div class="accounts-cmn__row">
      <account-cell-login
        class="account-item__cell"
        :account="item"
      />

      <p class="account-item__cell accounts-cmn__str accounts-cmn__str_sec">
        <ui-icon
          class="accounts-cmn__ico"
          icon="pin-alt"
        />

        <span>{{ formattedCountry }}</span>
      </p>

      <component
        :is="$can($USER_CLAIMS.EXTENSION_DEALERS) ? 'router-link': 'p'"
        :to="{
          name: 'extension.copart.dealers.entry',
          query: { copartLogin: item.id }
        }"
        class="account-item__cell accounts-cmn__str accounts-cmn__str_sec"
        :class="{
          'accounts-cmn__str_link': $can($USER_CLAIMS.EXTENSION_DEALERS)
        }"
      >
        <ui-icon
          class="accounts-cmn__ico"
          icon="user"
        />

        <span>{{ item.identitiesCount }}</span>
      </component>

      <component
        :is="$can($USER_CLAIMS.EXTENSION_WON) ? 'router-link': 'p'"
        :to="{
          name: 'extension.copart.won.entry',
          query: { copartLogin: item.id }
        }"
        class="account-item__cell accounts-cmn__str accounts-cmn__str_sec"
        :class="{ 'accounts-cmn__str_link': $can($USER_CLAIMS.EXTENSION_WON) }"
      >
        <ui-icon
          class="accounts-cmn__ico"
          icon="car-side"
        />

        <span>{{ item.winnersCount }}</span>
      </component>

      <account-cell-bid-limit
        class="account-item__cell"
        :account="item"
      />

      <account-cell-fee-level
        class="account-item__cell"
        :account="item"
      />

      <account-cell-status
        class="account-item__cell"
        :account="item"
      />

      <account-cell-operations
        class="account-item__cell account-item__cell_end"
        :account="item"
      />
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'

import AccountCellLogin from './AccountCellLogin'
import AccountCellBidLimit from './AccountCellBidLimit'
import AccountCellFeeLevel from './AccountCellFeeLevel'
import AccountCellStatus from './AccountCellStatus'
import AccountCellOperations from './AccountCellOperations'

import { ExtensionAccount } from 'Models/ExtensionAccount'
import { formatLocation } from 'Utils/formatLocation'

export default {
  name: 'account-item',
  components: {
    UiIcon,
    AccountCellLogin,
    AccountCellBidLimit,
    AccountCellFeeLevel,
    AccountCellStatus,
    AccountCellOperations,
  },

  props: {
    item: {
      type: ExtensionAccount,
      required: true,
    },
  },

  computed: {
    formattedCountry () {
      return this.item.country
        ? formatLocation(this.item.country)
        : this.$t('COMMON.SYMBOLS.MDASH')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/accounts";

.account-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__cell {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;
    gap: 0.4em;

    &_end {
      justify-self: end;
    }
  }
}
</style>
