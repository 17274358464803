import { DealerCommand } from './AccountCommand'
import axios from 'axios'

export class DeleteAccount extends DealerCommand {
  async execute () {
    console.log('this._dealerEntry', this._dealerEntry)
    try {
      const res = await axios.get('https://apitools.shelfroad.com/tech/shelf/delete', {
        params: {
          accountId: this._dealerEntry.id
        }
      })
      if (res.data) {
        const msg = this.$t('CMD.USERS.REMOVE.SUCCESS_MSG', {
          name: this._dealerEntry.id
        })
        this._reportSuccess(msg)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    } catch (error) {
      const msg = this.$t('CMD.USERS.REMOVE.FAILURE_MSG', {
        name: this._getName()
      })
      this._reportError(error, msg)
    }
  }
}
