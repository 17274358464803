export const FEE_LEVELS = Object.freeze({
  LOW: 'low',
  HIGH: 'high'
})

export const ALLOWED_ACCOUNT_COUNTRIES = Object.freeze([
  'ge',
  'us',
  'ua',
  'jo',
  'om',
  'ae',
])

export const ACCOUNTS_PAGE_LIMIT = 15

export const BID_LIMIT_CURRENCY = 'USD'
export const MIN_BID_LIMIT = 0
export const MAX_COPART_LOGIN_LENGTH = 16
