<template>
  <ui-portal>
    <ui-modal
      class="account-create-modal"
      :title="$t('CREATE_ACCOUNT_TITLE')"
      :show-close-btn="!isCreating"
      @close="close"
    >
      <form
        class="account-create-modal__form"
        :class="{ 'account-create-modal__form_loading': isCreating }"
        @submit.prevent="submit"
      >
        <div class="account-create-modal__field">
          {{ $t('COPART_LOGIN_LBL') }}
          <ui-text
            v-model="form.copartLogin"
            class="account-create-modal__field-input"
            :placeholder="$t('COPART_LOGIN_PH')"
            :is-error="Boolean(formErrors.copartLogin)"
          >
            <template #error>
              {{ $t(formErrors.copartLogin, {
                maxLength: MAX_COPART_LOGIN_LENGTH
              }) }}
            </template>
          </ui-text>
        </div>

        <div class="account-create-modal__field">
          {{ $t('COUNTRY_LBL') }}
          <ui-select
            v-model="form.country"
            class="account-create-modal__field-input"
            fill="frame"
            :placeholder="$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
            :options="countryOptions"
            :is-error="Boolean(formErrors.country)"
          >
            <template #error>
              {{ $t(formErrors.country) }}
            </template>
          </ui-select>
        </div>

        <div class="account-create-modal__field">
          {{ $t('BID_LIMIT_LBL') }}
          <ui-text
            v-model="form.bidLimit"
            type="number"
            class="account-create-modal__field-input"
            :placeholder="$t('BID_LIMIT_PH')"
            :is-error="Boolean(formErrors.bidLimit)"
          >
            <template #dock-left>
              {{ bidLimitCurrencySymbol }}
            </template>

            <template #error>
              {{ $t(formErrors.bidLimit, {
                min: MIN_BID_LIMIT
              }) }}
            </template>
          </ui-text>
        </div>

        <div class="account-create-modal__field">
          <div class="account-create-modal__field-tooltip-wrp">
            {{ $t('FEE_LEVEL_LBL') }}
            <div
              v-if="form.feeLevel === FEE_LEVELS.LOW"
              class="account-create-modal__field-tooltip"
              :aria-label="$t('LOW_FEE_LEVEL_TOOLTIP')"
              role="tooltip"
              data-microtip-position="bottom right"
              data-microtip-size="medium"
            >
              <ui-icon
                class="account-cell-fee-level__tooltip-icon"
                icon="info-alt"
              />
            </div>
          </div>

          <ui-select
            v-model="form.feeLevel"
            class="account-create-modal__field-input"
            fill="frame"
            :placeholder="$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
            :options="feeLevelOptions"
            :is-error="Boolean(formErrors.feeLevel)"
          >
            <template #error>
              {{ $t(formErrors.feeLevel) }}
            </template>
          </ui-select>
        </div>

        <div class="account-create-modal__field">
          {{ $t('PASSWORD_LBL') }}
          <ui-text
            v-model="form.password"
            class="account-create-modal__field-input"
            type="password"
            :placeholder="$t('PASSWORD_PH')"
            :is-error="Boolean(formErrors.password)"
          >
            <template #error>
              {{ $t(formErrors.password, {
                minLength: MIN_PASSWORD_LENGTH,
                maxLength: MAX_PASSWORD_LENGTH,
              }) }}
            </template>
          </ui-text>
        </div>

        <div class="account-create-modal__field">
          {{ $t('PASSWORD_CONFIRM_LBL') }}
          <ui-text
            v-model="form.passwordConfirm"
            class="account-create-modal__field-input"
            type="password"
            :placeholder="$t('PASSWORD_CONFIRM_PH')"
            :is-error="Boolean(formErrors.passwordConfirm)"
          >
            <template #error>
              {{ $t(formErrors.passwordConfirm) }}
            </template>
          </ui-text>
        </div>

        <div class="account-create-modal__buttons">
          <ui-button
            type="submit"
            class="account-create-modal__btn"
          >
            {{ $t('CREATE_ACCOUNT_BTN') }}
          </ui-button>

          <ui-button
            @click="close"
            type="button"
            class="account-create-modal__btn"
            look="secondary"
            fill="frame-hover"
          >
            {{ $t('CANCEL_BTN') }}
          </ui-button>
        </div>
      </form>

      <ui-spinner
        class="account-create-modal__loader"
        overlay
        type="pills"
        :show="isCreating"
      />
    </ui-modal>
  </ui-portal>
</template>

<script>
import {
  UiPortal,
  UiModal,
  UiText,
  UiIcon,
  UiSelect,
  UiButton,
  UiSpinner,
} from '@shelf.network/ui-kit'

import { createAccountFormSchema } from '../form-schemas/create-account'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'Utils/formValidators'
import {
  ALLOWED_ACCOUNT_COUNTRIES,
  BID_LIMIT_CURRENCY,
  FEE_LEVELS,
  MAX_COPART_LOGIN_LENGTH,
  MIN_BID_LIMIT
} from '../constants'

import { scrollToErrorInModal } from 'Utils/scrollTo'
import { formatLocation } from 'Utils/formatLocation'
import { mapActions, mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'
import { currencySymbols } from 'Utils/currencySymbols'
import { extensionAccountsActions } from '../store/types'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'account-create-modal',
  components: {
    UiPortal,
    UiModal,
    UiText,
    UiIcon,
    UiSelect,
    UiButton,
    UiSpinner,
  },

  data () {
    return {
      form: createAccountFormSchema.form,
      formErrors: createAccountFormSchema.formErrors,
      isCreating: false,
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      platform: userGetters.PLATFORM,
    }),

    feeLevelOptions () {
      return [
        { value: FEE_LEVELS.LOW, label: this.$t('FEE_LEVEL_LOW') },
        { value: FEE_LEVELS.HIGH, label: this.$t('FEE_LEVEL_HIGH') },
      ]
    },

    countryOptions () {
      return ALLOWED_ACCOUNT_COUNTRIES.map(value => ({
        value,
        label: formatLocation(value)
      }))
    },

    bidLimitCurrencySymbol () {
      return currencySymbols[BID_LIMIT_CURRENCY]
    },

    MIN_PASSWORD_LENGTH: () => MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH: () => MAX_PASSWORD_LENGTH,
    MAX_COPART_LOGIN_LENGTH: () => MAX_COPART_LOGIN_LENGTH,
    MIN_BID_LIMIT: () => MIN_BID_LIMIT,
    FEE_LEVELS: () => FEE_LEVELS,
  },

  created () {
    createAccountFormSchema.resetFields()

    const countryCode = this.platform.countryCode.toLowerCase()
    if (ALLOWED_ACCOUNT_COUNTRIES.includes(countryCode)) {
      this.form.country = countryCode
    }
  },

  methods: {
    ...mapActions('ui/extension-accounts', {
      createAccount: extensionAccountsActions.CREATE_ACCOUNT,
    }),

    async submit () {
      if (!createAccountFormSchema.validateForm()) {
        scrollToErrorInModal()
        return
      }

      this.isCreating = true
      try {
        await this.createAccount()

        showSuccess(this.$t('ACCOUNT_SUCCESS_MSG', {
          account: this.form.copartLogin
        }))
        this.close()
      } catch (err) {
        if (err.httpStatus === 409) {
          this.formErrors.copartLogin = 'CONFLICT_LOGIN_ERROR'
          this.isCreating = false
          return
        }

        console.error(err)
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
      }

      this.isCreating = false
    },

    close () {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.account-create-modal {
  &__form {
    max-width: 100%;
    width: 30em;

    &_loading {
      filter: blur(0.2em);
    }
  }

  &__field {
    margin-bottom: 2em;

    &-tooltip-wrp {
      display: flex;
    }

    &-tooltip {
      width: max-content;
      cursor: help;
      margin-left: 0.4em;

      &::after {
        line-height: 1.5;
      }
    }

    &-input {
      margin-top: 0.4em;

      /deep/ .ui-text {
        &__dock {
          width: 3em;
        }

        &__dock_left ~ .ui-text__input {
          padding-left: 2.6em;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid $color-light-grey;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    min-width: 14em;

    @include respond-below(sm) {
      min-width: 0;
    }
  }

  & /deep/ .modal__content {
    max-width: 36em;

    @include respond-below(sm) {
      max-width: unset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_ACCOUNT_BTN": "Create account",
    "CREATE_ACCOUNT_TITLE": "Account create",
    "CANCEL_BTN": "Cancel",
    "COPART_LOGIN_LBL": "COPART LOGIN",
    "COPART_LOGIN_PH": "Copart buyer number",
    "COUNTRY_LBL": "LOCATION",
    "BID_LIMIT_LBL": "BID LIMIT",
    "BID_LIMIT_PH": "Account bid limit",
    "FEE_LEVEL_LBL": "FEE",
    "FEE_LEVEL_LOW": "Low",
    "FEE_LEVEL_HIGH": "High",
    "LOW_FEE_LEVEL_TOOLTIP": "If invoice fee is greater than calculated fee, account will be disabled",
    "PASSWORD_LBL": "ACCOUNT PASSWORD",
    "PASSWORD_PH": "Pick a password",
    "PASSWORD_CONFIRM_LBL": "PASSWORD AGAIN",
    "PASSWORD_CONFIRM_PH": "Repeat the password",
    "CONFLICT_LOGIN_ERROR": "Account with such login already exists",
    "ACCOUNT_SUCCESS_MSG": "Account {account} was successfully created"
  },
  "ka": {
    "CREATE_ACCOUNT_BTN": "ახალი შექმნა",
    "CREATE_ACCOUNT_TITLE": "ახალი შექმნა",
    "CANCEL_BTN": "დახურვა",
    "COPART_LOGIN_LBL": "COPART-ᲖᲔ ᲨᲔᲡᲕᲚᲐ",
    "COPART_LOGIN_PH": "ექაუნთის ნომერი",
    "COUNTRY_LBL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "BID_LIMIT_LBL": "ᲑᲘᲯᲘᲡ ᲚᲘᲛᲘᲢᲘ",
    "BID_LIMIT_PH": "ექაუნთების ლიმიტი",
    "FEE_LEVEL_LBL": "ᲓᲐᲠᲘᲪᲮᲕᲐ",
    "FEE_LEVEL_LOW": "დაბალი",
    "FEE_LEVEL_HIGH": "მაღალი",
    "LOW_FEE_LEVEL_TOOLTIP": "თუ ინვოისის ღირებულება, დარიცხვაზე მეტია ექაუნთი გაუქმნდება",
    "PASSWORD_LBL": "ახალი ᲞᲐᲠᲝᲚᲘ",
    "PASSWORD_PH": "აარჩიეთ პაროლი",
    "PASSWORD_CONFIRM_LBL": "ᲒᲐᲘᲛᲔᲝᲠᲔᲗ ᲞᲐᲠᲝᲚᲘ",
    "PASSWORD_CONFIRM_PH": "გაიმეორეთ პაროლი",
    "CONFLICT_LOGIN_ERROR": "აღნიშნული ექაუნთი უკვე არსებობს",
    "ACCOUNT_SUCCESS_MSG": "ექაუნთი {account} წარმატებით დაემატა"
  },
  "ru": {
    "CREATE_ACCOUNT_BTN": "Создать аккаунт",
    "CREATE_ACCOUNT_TITLE": "Создание аккаунта",
    "CANCEL_BTN": "Закрыть",
    "COPART_LOGIN_LBL": "ЛОГИН COPART",
    "COPART_LOGIN_PH": "Номер покупателя Copart",
    "COUNTRY_LBL": "ЛОКАЦИЯ",
    "BID_LIMIT_LBL": "ЛИМИТ СТАВКИ",
    "BID_LIMIT_PH": "Лимит ставки аккаунта",
    "FEE_LEVEL_LBL": "КОМИССИЯ",
    "FEE_LEVEL_LOW": "Низкая",
    "FEE_LEVEL_HIGH": "Высокая",
    "LOW_FEE_LEVEL_TOOLTIP": "Если комиссия инвойса превысит расчётную, аккаунт будет отключен",
    "PASSWORD_LBL": "ПАРОЛЬ",
    "PASSWORD_PH": "Придумайте пароль",
    "PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "PASSWORD_CONFIRM_PH": "Повторите пароль",
    "CONFLICT_LOGIN_ERROR": "Аккаунт с таким логином уже существует",
    "ACCOUNT_SUCCESS_MSG": "Аккаунт {account} успешно создан"
  },
  "uk": {
    "CREATE_ACCOUNT_BTN": "Створити акаунт",
    "CREATE_ACCOUNT_TITLE": "Створення акаунта",
    "CANCEL_BTN": "Закрити",
    "COPART_LOGIN_LBL": "ЛОГІН COPART",
    "COPART_LOGIN_PH": "Номер покупця Copart",
    "COUNTRY_LBL": "ЛОКАЦІЯ",
    "BID_LIMIT_LBL": "ЛІМІТ СТАВКИ",
    "BID_LIMIT_PH": "Ліміт ставки акаунта",
    "FEE_LEVEL_LBL": "КОМІСІЯ",
    "FEE_LEVEL_LOW": "Низька",
    "FEE_LEVEL_HIGH": "Висока",
    "LOW_FEE_LEVEL_TOOLTIP": "Якщо комісія інвойсу перевищить розрахункову, аккаунт буде відключений",
    "PASSWORD_LBL": "ПАРОЛЬ",
    "PASSWORD_PH": "Придумайте пароль",
    "PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "PASSWORD_CONFIRM_PH": "Повторіть пароль",
    "CONFLICT_LOGIN_ERROR": "Аккаунт з таким логіном вже існує",
    "ACCOUNT_SUCCESS_MSG": "Акаунт {account} успішно створено"
  }
}
</i18n>
