<template>
  <div class="account-cell-operations accounts-cmn__cell">
    <ui-dropdown
      class="account-cell-operations__dropdown"
      ref="dropdownComponent"
      :clickaway="isClickawayEnabled"
      bubble
    >
      <button class="account-cell-operations__dropdown-trigger">
        <ui-icon
          class="account-cell-operations__dropdown-trigger-ico"
          icon="dots-v"
        />
      </button>

      <template slot="content">
        <div class="account-cell-operations__dropdown-content">
          <op-edit-password
            class="account-cell-operations__op"
            :account="account"
            @op-started="isClickawayEnabled = false"
            @op-finished="isClickawayEnabled = true"
          />

          <op-block
            class="account-cell-operations__op"
            :account="account"
          />

          <op-ios
            class="account-cell-operations__op"
            :account="account"
          />

          <op-delete
            class="account-cell-operations__op"
            :account="account"
            v-if="!isActive"
          />
        </div>
      </template>
    </ui-dropdown>
  </div>
</template>

<script>
import { UiIcon, UiDropdown } from '@shelf.network/ui-kit'

import OpEditPassword from './operations/OpEditPassword'
import OpBlock from './operations/OpBlock'
import OpIos from './operations/OpIos'
import OpDelete from './operations/OpDelete'

import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  name: 'account-cell-operations',
  components: {
    UiIcon,
    UiDropdown,

    OpEditPassword,
    OpBlock,
    OpIos,
    OpDelete
  },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  data () {
    return {
      isClickawayEnabled: true,
    }
  },

  computed: {
    isActive () {
      return this.account.isConnectorAvailable && this.account.isLoginAllowed
    }
  },

}
</script>

<style lang="scss" scoped>
@import "../styles/accounts.scss";

.account-cell-operations {
  /deep/ .ui-dropdown__content {
    transform: unset;
    left: unset;
    right: 0;
    border-radius: 1em;
    z-index: z-index(1);

    &::before {
      left: unset;
      right: 1.2em;
    }
  }

  &__dropdown {
    &-trigger {
      background: $color-light-grey;
      border: none;
      text-decoration: none;
      text-align: center;
      color: $color-ui-default;
      width: 3.55em;
      height: 3.55em;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 180ms;

      &-ico {
        display: block;
        color: $color-ui-secondary;
        font-size: 1.2em;
        padding-top: 0.15em;
      }
    }

    &-content {
      display: grid;
      grid: auto-flow auto / auto;
      gap: 0.75em;
      padding: 1.25em 0;
      min-width: 20em;
    }
  }
}
</style>
